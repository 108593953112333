/* arabic */
@font-face {
  font-family: '__Rubik_c2ecba';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/356abdd51b933898-s.woff2) format('woff2');
  unicode-range: U+0600-06FF, U+0750-077F, U+0870-088E, U+0890-0891, U+0897-08E1, U+08E3-08FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE70-FE74, U+FE76-FEFC, U+102E0-102FB, U+10E60-10E7E, U+10EC2-10EC4, U+10EFC-10EFF, U+1EE00-1EE03, U+1EE05-1EE1F, U+1EE21-1EE22, U+1EE24, U+1EE27, U+1EE29-1EE32, U+1EE34-1EE37, U+1EE39, U+1EE3B, U+1EE42, U+1EE47, U+1EE49, U+1EE4B, U+1EE4D-1EE4F, U+1EE51-1EE52, U+1EE54, U+1EE57, U+1EE59, U+1EE5B, U+1EE5D, U+1EE5F, U+1EE61-1EE62, U+1EE64, U+1EE67-1EE6A, U+1EE6C-1EE72, U+1EE74-1EE77, U+1EE79-1EE7C, U+1EE7E, U+1EE80-1EE89, U+1EE8B-1EE9B, U+1EEA1-1EEA3, U+1EEA5-1EEA9, U+1EEAB-1EEBB, U+1EEF0-1EEF1;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Rubik_c2ecba';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/1a4dd1d7cd3232ea-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Rubik_c2ecba';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/341baa6ce7a16e81-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
  font-family: '__Rubik_c2ecba';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/d70c23d6fe66d464-s.woff2) format('woff2');
  unicode-range: U+0307-0308, U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: '__Rubik_c2ecba';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/0596140cb8d9223a-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Rubik_c2ecba';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/c22ccc5eb58b83e1-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* arabic */
@font-face {
  font-family: '__Rubik_c2ecba';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/356abdd51b933898-s.woff2) format('woff2');
  unicode-range: U+0600-06FF, U+0750-077F, U+0870-088E, U+0890-0891, U+0897-08E1, U+08E3-08FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE70-FE74, U+FE76-FEFC, U+102E0-102FB, U+10E60-10E7E, U+10EC2-10EC4, U+10EFC-10EFF, U+1EE00-1EE03, U+1EE05-1EE1F, U+1EE21-1EE22, U+1EE24, U+1EE27, U+1EE29-1EE32, U+1EE34-1EE37, U+1EE39, U+1EE3B, U+1EE42, U+1EE47, U+1EE49, U+1EE4B, U+1EE4D-1EE4F, U+1EE51-1EE52, U+1EE54, U+1EE57, U+1EE59, U+1EE5B, U+1EE5D, U+1EE5F, U+1EE61-1EE62, U+1EE64, U+1EE67-1EE6A, U+1EE6C-1EE72, U+1EE74-1EE77, U+1EE79-1EE7C, U+1EE7E, U+1EE80-1EE89, U+1EE8B-1EE9B, U+1EEA1-1EEA3, U+1EEA5-1EEA9, U+1EEAB-1EEBB, U+1EEF0-1EEF1;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Rubik_c2ecba';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/1a4dd1d7cd3232ea-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Rubik_c2ecba';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/341baa6ce7a16e81-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
  font-family: '__Rubik_c2ecba';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/d70c23d6fe66d464-s.woff2) format('woff2');
  unicode-range: U+0307-0308, U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: '__Rubik_c2ecba';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/0596140cb8d9223a-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Rubik_c2ecba';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/c22ccc5eb58b83e1-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* arabic */
@font-face {
  font-family: '__Rubik_c2ecba';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/356abdd51b933898-s.woff2) format('woff2');
  unicode-range: U+0600-06FF, U+0750-077F, U+0870-088E, U+0890-0891, U+0897-08E1, U+08E3-08FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE70-FE74, U+FE76-FEFC, U+102E0-102FB, U+10E60-10E7E, U+10EC2-10EC4, U+10EFC-10EFF, U+1EE00-1EE03, U+1EE05-1EE1F, U+1EE21-1EE22, U+1EE24, U+1EE27, U+1EE29-1EE32, U+1EE34-1EE37, U+1EE39, U+1EE3B, U+1EE42, U+1EE47, U+1EE49, U+1EE4B, U+1EE4D-1EE4F, U+1EE51-1EE52, U+1EE54, U+1EE57, U+1EE59, U+1EE5B, U+1EE5D, U+1EE5F, U+1EE61-1EE62, U+1EE64, U+1EE67-1EE6A, U+1EE6C-1EE72, U+1EE74-1EE77, U+1EE79-1EE7C, U+1EE7E, U+1EE80-1EE89, U+1EE8B-1EE9B, U+1EEA1-1EEA3, U+1EEA5-1EEA9, U+1EEAB-1EEBB, U+1EEF0-1EEF1;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Rubik_c2ecba';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/1a4dd1d7cd3232ea-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Rubik_c2ecba';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/341baa6ce7a16e81-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
  font-family: '__Rubik_c2ecba';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/d70c23d6fe66d464-s.woff2) format('woff2');
  unicode-range: U+0307-0308, U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: '__Rubik_c2ecba';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/0596140cb8d9223a-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Rubik_c2ecba';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/c22ccc5eb58b83e1-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* arabic */
@font-face {
  font-family: '__Rubik_c2ecba';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/356abdd51b933898-s.woff2) format('woff2');
  unicode-range: U+0600-06FF, U+0750-077F, U+0870-088E, U+0890-0891, U+0897-08E1, U+08E3-08FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE70-FE74, U+FE76-FEFC, U+102E0-102FB, U+10E60-10E7E, U+10EC2-10EC4, U+10EFC-10EFF, U+1EE00-1EE03, U+1EE05-1EE1F, U+1EE21-1EE22, U+1EE24, U+1EE27, U+1EE29-1EE32, U+1EE34-1EE37, U+1EE39, U+1EE3B, U+1EE42, U+1EE47, U+1EE49, U+1EE4B, U+1EE4D-1EE4F, U+1EE51-1EE52, U+1EE54, U+1EE57, U+1EE59, U+1EE5B, U+1EE5D, U+1EE5F, U+1EE61-1EE62, U+1EE64, U+1EE67-1EE6A, U+1EE6C-1EE72, U+1EE74-1EE77, U+1EE79-1EE7C, U+1EE7E, U+1EE80-1EE89, U+1EE8B-1EE9B, U+1EEA1-1EEA3, U+1EEA5-1EEA9, U+1EEAB-1EEBB, U+1EEF0-1EEF1;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Rubik_c2ecba';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/1a4dd1d7cd3232ea-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Rubik_c2ecba';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/341baa6ce7a16e81-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
  font-family: '__Rubik_c2ecba';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/d70c23d6fe66d464-s.woff2) format('woff2');
  unicode-range: U+0307-0308, U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: '__Rubik_c2ecba';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/0596140cb8d9223a-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Rubik_c2ecba';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/c22ccc5eb58b83e1-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Rubik_Fallback_c2ecba';src: local("Arial");ascent-override: 88.57%;descent-override: 23.68%;line-gap-override: 0.00%;size-adjust: 105.57%
}.__className_c2ecba {font-family: '__Rubik_c2ecba', '__Rubik_Fallback_c2ecba';font-style: normal
}

.advanced-cropper-line-wrapper--north, .advanced-cropper-line-wrapper--south {
  height: 12px;
  width: 100%;
}

.advanced-cropper-line-wrapper--north {
  cursor: n-resize;
}

.advanced-cropper-line-wrapper--south {
  cursor: s-resize;
}

.advanced-cropper-line-wrapper--east, .advanced-cropper-line-wrapper--west {
  width: 12px;
  height: 100%;
}

.advanced-cropper-line-wrapper--east {
  cursor: e-resize;
}

.advanced-cropper-line-wrapper--west {
  cursor: w-resize;
}

.advanced-cropper-line-wrapper--disabled {
  cursor: auto;
}

.advanced-cropper-line-wrapper__content {
  position: absolute;
}

.advanced-cropper-line-wrapper__content--east, .advanced-cropper-line-wrapper__content--west {
  height: 100%;
}

.advanced-cropper-line-wrapper__content--north, .advanced-cropper-line-wrapper__content--south {
  width: 100%;
}

.advanced-cropper-line-wrapper__content--east {
  left: 50%;
  transform: translateX(-100%);
}

.advanced-cropper-line-wrapper__content--west {
  right: 50%;
  transform: translateX(100%);
}

.advanced-cropper-line-wrapper__content--north {
  top: 50%;
}

.advanced-cropper-line-wrapper__content--south {
  bottom: 50%;
}

.advanced-cropper-handler-wrapper {
  position: absolute;
  transform: translate(-50%, -50%);
  width: 30px;
  height: 30px;
}

.advanced-cropper-handler-wrapper__draggable {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.advanced-cropper-handler-wrapper--west-north {
  cursor: nw-resize;
}

.advanced-cropper-handler-wrapper--north {
  cursor: n-resize;
}

.advanced-cropper-handler-wrapper--east-north {
  cursor: ne-resize;
}

.advanced-cropper-handler-wrapper--east {
  cursor: e-resize;
}

.advanced-cropper-handler-wrapper--east-south {
  cursor: se-resize;
}

.advanced-cropper-handler-wrapper--south {
  cursor: s-resize;
}

.advanced-cropper-handler-wrapper--west-south {
  cursor: sw-resize;
}

.advanced-cropper-handler-wrapper--west {
  cursor: w-resize;
}

.advanced-cropper-handler-wrapper--disabled {
  cursor: auto;
}

.advanced-cropper-bounding-box {
  position: relative;
  height: 100%;
  width: 100%;
}

.advanced-cropper-bounding-box__handler-wrapper {
  position: absolute;
}

.advanced-cropper-bounding-box__handler-wrapper--east, .advanced-cropper-bounding-box__handler-wrapper--west {
  top: 50%;
  transform: translateY(-50%);
  height: 100%;
}

.advanced-cropper-bounding-box__handler-wrapper--south, .advanced-cropper-bounding-box__handler-wrapper--north {
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
}

.advanced-cropper-bounding-box__handler-wrapper--west, .advanced-cropper-bounding-box__handler-wrapper--west-north, .advanced-cropper-bounding-box__handler-wrapper--west-south {
  left: 0;
}

.advanced-cropper-bounding-box__handler-wrapper--east, .advanced-cropper-bounding-box__handler-wrapper--east-north, .advanced-cropper-bounding-box__handler-wrapper--east-south {
  left: 100%;
}

.advanced-cropper-bounding-box__handler-wrapper--north, .advanced-cropper-bounding-box__handler-wrapper--west-north, .advanced-cropper-bounding-box__handler-wrapper--east-north {
  top: 0;
}

.advanced-cropper-bounding-box__handler-wrapper--south, .advanced-cropper-bounding-box__handler-wrapper--west-south, .advanced-cropper-bounding-box__handler-wrapper--east-south {
  top: 100%;
}

.advanced-cropper-bounding-box__handler {
  position: absolute;
}

.advanced-cropper-bounding-box__handler--west-north {
  left: 0;
  top: 0;
}

.advanced-cropper-bounding-box__handler--north {
  left: 50%;
  top: 0;
}

.advanced-cropper-bounding-box__handler--east-north {
  left: 100%;
  top: 0;
}

.advanced-cropper-bounding-box__handler--east {
  left: 100%;
  top: 50%;
}

.advanced-cropper-bounding-box__handler--east-south {
  left: 100%;
  top: 100%;
}

.advanced-cropper-bounding-box__handler--south {
  left: 50%;
  top: 100%;
}

.advanced-cropper-bounding-box__handler--west-south {
  left: 0;
  top: 100%;
}

.advanced-cropper-bounding-box__handler--west {
  left: 0;
  top: 50%;
}

.advanced-cropper-bounding-box__line {
  position: absolute;
}

.advanced-cropper-bounding-box__line--north, .advanced-cropper-bounding-box__line--south {
  left: 0;
  transform: translateY(-50%);
}

.advanced-cropper-bounding-box__line--north {
  top: 0;
}

.advanced-cropper-bounding-box__line--south {
  top: 100%;
}

.advanced-cropper-bounding-box__line--west, .advanced-cropper-bounding-box__line--east {
  top: 0;
  transform: translateX(-50%);
}

.advanced-cropper-bounding-box__line--west {
  left: 0;
}

.advanced-cropper-bounding-box__line--east {
  left: 100%;
}

.advanced-cropper-artificial-transition {
  will-change: transform;
}

.advanced-cropper-background-image {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  position: absolute;
  transform-origin: center;
  pointer-events: none;
  max-width: none !important;
}

.advanced-cropper-canvas {
  display: none;
}

.advanced-cropper-source {
  width: 1px;
  height: 1px;
  visibility: hidden;
  position: absolute;
  opacity: 0;
}

.advanced-cropper-fade {
  visibility: hidden;
  opacity: 0;
  transition: 0.5s;
  display: flex;
  flex-direction: column;
}

.advanced-cropper-fade--visible {
  opacity: 1;
  visibility: visible;
}

.advanced-cropper-wrapper__fade {
  flex-grow: 1;
  min-height: 0;
}

.advanced-cropper-stencil-grid {
  display: table;
  border-collapse: collapse;
  table-layout: fixed;
  opacity: 0;
  transition: opacity 0.3s;
}

.advanced-cropper-stencil-grid--visible {
  opacity: 1;
}

.advanced-cropper-stencil-grid__row {
  display: table-row;
}

.advanced-cropper-stencil-grid__cell {
  display: table-cell;
  width: 1%;
  height: 1%;
  border: currentColor solid 1px;
}

.advanced-cropper-stencil-grid__cell--top {
  border-top-color: transparent;
}

.advanced-cropper-stencil-grid__cell--left {
  border-left-color: transparent;
}

.advanced-cropper-stencil-grid__cell--right {
  border-right-color: transparent;
}

.advanced-cropper-stencil-grid__cell--bottom {
  border-bottom-color: transparent;
}

.advanced-cropper-stencil-overlay {
  box-sizing: content-box;
  box-shadow: 0 0 0 1000px currentColor;
  pointer-events: none;
  width: 100%;
  height: 100%;
  position: absolute;
  -webkit-backface-visibility: hidden;
}

.advanced-cropper-stencil-wrapper {
  will-change: transform;
}

.advanced-cropper-boundary {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  direction: ltr;
  position: relative;
}

.advanced-cropper-boundary__content {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.advanced-cropper-boundary__stretcher {
  pointer-events: none;
  position: relative;
  max-width: 100%;
  max-height: 100%;
}

.advanced-cropper-circle-stencil {
  position: absolute;
  height: 100%;
  width: 100%;
  box-sizing: content-box;
  -webkit-backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
}

.advanced-cropper-circle-stencil__overlay {
  border-radius: 50%;
  overflow: hidden;
}

.advanced-cropper-circle-stencil__preview {
  border-radius: 50%;
}

.advanced-cropper-circle-stencil__draggable-area, .advanced-cropper-circle-stencil__overlay, .advanced-cropper-circle-stencil__preview, .advanced-cropper-circle-stencil__grid {
  position: absolute;
  height: 100%;
  width: 100%;
}

.advanced-cropper-circle-stencil--movable {
  cursor: move;
}

.advanced-cropper-rectangle-stencil {
  position: absolute;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  -webkit-backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
}

.advanced-cropper-rectangle-stencil__draggable-area, .advanced-cropper-rectangle-stencil__overlay, .advanced-cropper-rectangle-stencil__preview, .advanced-cropper-rectangle-stencil__grid {
  position: absolute;
  height: 100%;
  width: 100%;
}

.advanced-cropper-rectangle-stencil--movable {
  cursor: move;
}

.advanced-cropper-simple-line {
  background: none;
  border-width: 0;
}

.advanced-cropper-simple-line--south, .advanced-cropper-simple-line--north {
  height: 0;
  width: 100%;
}

.advanced-cropper-simple-line--east, .advanced-cropper-simple-line--west {
  height: 100%;
  width: 0;
}

.advanced-cropper-simple-line--east {
  border-right-width: 1px;
}

.advanced-cropper-simple-line--west {
  border-left-width: 1px;
}

.advanced-cropper-simple-line--south {
  border-bottom-width: 1px;
}

.advanced-cropper-simple-line--north {
  border-top-width: 1px;
}

.advanced-cropper-simple-handler {
  display: block;
}

.advanced-cropper-preview {
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
}

.advanced-cropper-preview__content {
  overflow: hidden;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.advanced-cropper-preview__image {
  display: none;
  pointer-events: none;
  position: absolute;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  transform-origin: center;
  max-width: none !important;
}

.advanced-cropper-preview__image--visible {
  display: block;
}

.advanced-cropper-preview__boundary {
  flex-grow: 1;
  min-height: 0;
  min-width: 0;
}

.cropper-preview-wrapper__fade {
  flex-grow: 1;
  min-height: 0;
  width: 100%;
}

.advanced-cropper {
  overflow: hidden;
  max-height: 100%;
  background: black;
  position: relative;
  display: flex;
  flex-direction: column;
  color: white;
}

.advanced-cropper__boundary {
  flex-grow: 1;
  min-height: 0;
  min-width: 0;
}

.advanced-cropper__wrapper, .advanced-cropper__background-wrapper {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  position: absolute;
}

.advanced-cropper__stencil-wrapper {
  position: absolute;
}

.advanced-cropper * {
  box-sizing: border-box;
}

.advanced-cropper-simple-handler {
  background: currentColor;
  height: 10px;
  width: 10px;
}

.advanced-cropper-simple-line {
  transition: border 0.5s;
  border-color: rgba(255, 255, 255, 0.3);
  border-style: solid;
}

.advanced-cropper-simple-line--hover {
  border-color: white;
}

.advanced-cropper-circle-stencil__preview {
  border: solid 2px rgba(255, 255, 255, 0.2);
}

.advanced-cropper-stencil-overlay {
  color: rgba(0, 0, 0, 0.5);
}

.advanced-cropper-stencil-grid {
  color: rgba(255, 255, 255, 0.4);
}

.advanced-cropper-simple-line {
  border-color: rgba(255, 255, 255, 0.7);
}
.advanced-cropper-simple-line--east {
  border-right-width: 2px;
}
.advanced-cropper-simple-line--west {
  border-left-width: 2px;
}
.advanced-cropper-simple-line--south {
  border-bottom-width: 2px;
}
.advanced-cropper-simple-line--north {
  border-top-width: 2px;
}
.advanced-cropper-simple-line--hover {
  border-color: white;
}

.advanced-cropper-bounding-box__handler {
  width: 24px;
  height: 24px;
}
.advanced-cropper-bounding-box__handler--west-north {
  transform: translate(-5px, -5px);
}
.advanced-cropper-bounding-box__handler--east-south {
  transform: translate(-19px, -19px);
}
.advanced-cropper-bounding-box__handler--west-south {
  transform: translate(-5px, -19px);
}
.advanced-cropper-bounding-box__handler--east-north {
  transform: translate(-19px, -5px);
}

.advanced-cropper-simple-handler {
  display: block;
  position: relative;
  flex-shrink: 0;
  transition: opacity 0.5s;
  border: none;
  background: currentColor;
  top: auto;
  left: auto;
  height: 0px;
  width: 0px;
  opacity: 0;
}
.advanced-cropper-simple-handler--west-north, .advanced-cropper-simple-handler--east-south, .advanced-cropper-simple-handler--west-south, .advanced-cropper-simple-handler--east-north {
  display: block;
  height: 16px;
  width: 16px;
  background: none;
  opacity: 1;
}
.advanced-cropper-simple-handler--west-north {
  border-left: solid 3px currentColor;
  border-top: solid 3px currentColor;
}
.advanced-cropper-simple-handler--east-south {
  border-right: solid 3px currentColor;
  border-bottom: solid 3px currentColor;
}
.advanced-cropper-simple-handler--west-south {
  border-left: solid 3px currentColor;
  border-bottom: solid 3px currentColor;
}
.advanced-cropper-simple-handler--east-north {
  border-right: solid 3px currentColor;
  border-top: solid 3px currentColor;
}
.advanced-cropper-simple-handler--hover {
  opacity: 1;
}

.advanced-cropper-circle-stencil {
  border-color: rgba(255, 255, 255, 0.4);
}

.advanced-cropper-stencil-grid {
  color: rgba(255, 255, 255, 0.5);
}

.rmc-cropper {
  color: #61DAFB;
}

.rmc-cropper-wrapper {
  padding: 40px 10px;
  overflow: hidden;
  background: black;
  max-height: 100%;
}
.rmc-cropper-wrapper--with-navigation {
  padding-bottom: 80px;
}
.rmc-cropper-wrapper__content {
  position: relative;
}
.rmc-cropper-wrapper__fade {
  flex-grow: 1;
  min-height: 0;
}
.rmc-cropper-wrapper__navigation {
  position: absolute;
  bottom: 0;
  max-width: 450px;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
}
.rmc-cropper-wrapper__spinner {
  visibility: hidden;
  opacity: 0;
  transition: 0.5s;
  transition-delay: 0s;
  stroke: currentColor;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.rmc-cropper-wrapper__spinner--visible {
  transition-delay: 0.5s;
  opacity: 1;
  visibility: visible;
}

.rmc-navigation {
  display: flex;
  align-items: center;
  padding: 20px 15px;
}
.rmc-navigation__rotator {
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
}
.rmc-navigation__button {
  cursor: pointer;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin: 0 5px;
  background: none;
  border: none;
  outline: none;
  transition: transform 0.5s;
  padding: 0;
}
.rmc-navigation__button:hover, .rmc-navigation__button:focus {
  transform: scale(1.1);
}

.rmc-rotate-component__bars {
  cursor: -webkit-grab;
  cursor: grab;
  width: 100%;
  display: flex;
  min-width: 0;
  position: relative;
  height: 15px;
}
.rmc-rotate-component__bars--dragging {
  cursor: -webkit-grabbing !important;
  cursor: grabbing !important;
}
.rmc-rotate-component__bar {
  position: absolute;
  left: 0;
  height: 15px;
  width: 3px;
  flex-shrink: 0;
  top: 50%;
  transform: translateY(-50%);
  background: white;
}
.rmc-rotate-component__bar--highlighted {
  background-color: currentColor;
}
.rmc-rotate-component__bar--zero {
  height: 20px;
}
.rmc-rotate-component__value {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 3px;
  background-color: currentColor;
  color: currentColor;
  height: 25px;
  border-radius: 2px;
}
.rmc-rotate-component__value-number {
  position: absolute;
  top: -20px;
  color: inherit;
  left: 50%;
  transform: translateX(-50%);
  font-size: 12px;
}

body.dragging {
  cursor: -webkit-grabbing !important;
  cursor: grabbing !important;
}
